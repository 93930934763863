import styled from '@emotion/styled';
import { a } from 'react-spring';

export const ServicesContainer = styled.section`
  position: relative;
  padding: 36px 38px;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 36px 10%;
  }

  @media (min-width: 1280px) {
    display: block;
    width: 80%;
    margin: 0 auto;
    padding: 0;
  }
`;

export const TitleServices = styled(a.h2)`
  color: #1f3463;
  font-size: 4rem;
  font-family: 'Sofia Pro', sans-serif;
  @media (min-width: 1280px) {
    text-align: left;
    font-size: 8rem;
  }
`;

export const ParagrahpServices = styled(a.p)`
  color: #1f3463;
  font-size: 1.5rem;
  margin-bottom: 4rem;
  width: 100%;
  @media (min-width: 1280px) {
    text-align: left;
    width: 700px;
  }
`;

export const ServiceContent = styled(a.div)`
  width: 100%;
  padding: 2rem 0rem;
  position: relative;
`;

const selectBg = idx =>
  ({
    0: ({ theme }) => theme.colors.primary,
    1: ({ theme }) => theme.colors.dark,
    2: ({ theme }) => theme.colors.info,
    3: ({ theme }) => theme.colors.warning,
    4: ({ theme }) => theme.colors.danger,
    5: ({ theme }) => theme.colors.success,
  }[idx ?? 0]);

export const BoxColor = styled.div`
  padding: 6rem 0rem;
  border-radius: 2rem;
  background: ${({ idx }) => selectBg(idx)};
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  @media (min-width: 1280px) {
    width: 70%;
  }
`;

export const ColumsInfo = styled.div`
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column-reverse;
  padding: 2rem 0rem;
  @media (min-width: 1280px) {
    flex-direction: row;
    height: 479px;
  }
`;

export const BlurAndImage = styled.div`
  padding: 2rem;
  position: relative;

  @media (min-width: 992px) {
    width: 100%;
  }

  @media (min-width: 1280px) {
    border-left: 5px solid ${({ idx }) => selectBg(idx)};
    border-right: 8px solid #fff;
    width: 50%;
    height: 100%;
  }
`;

export const ColumsContentBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  @media (min-width: 1280px) {
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.25);
  }
`;

export const ColumsImage = styled.div`
  filter: blur(0px);
  width: 100%;
  @media (min-width: 1280px) {
    height: 323px;
  }
`;

const getTitleSize = size =>
  ({
    sm: '5rem',
    md: '7rem',
    bg: '8rem',
  }[size ?? 'md']);

export const ColumsInfoAereo = styled(a.div)`
  color: #fff;
  width: 100%;
  padding: 0rem 2.7rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0) 20%);

  @media (min-width: 1280px) {
    padding: 0rem 4.5rem;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0) 50%);
    width: 50%;
    min-height: 363px;
    height: 100%;
  }
  h3 {
    font-size: ${({ sizeTitle, title }) =>
      title === 'Colombia - USA' ? '35px' : `calc(${getTitleSize(sizeTitle)} - 3rem)`};
    text-align: center;
    font-family: 'Sofia Pro', sans-serif;
    @media (min-width: 1280px) {
      font-size: ${({ sizeTitle }) => getTitleSize(sizeTitle)};
    }
  }
  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
    @media (min-width: 1280px) {
      text-align: left;
    }
  }
  ol {
    display: flex;
    margin-top: 1rem;
    font-size: 1.5rem;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1280px) {
      display: block;
      margin-left: 2rem;
    }
  }
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 25px;

  @media (min-width: 1280px) {
    margin-top: 0;
    height: 100%;
    justify-content: ${({ idx }) => (idx === 5 || idx === 2 ? 'start' : 'center')};
  }
`;

export const AereoImageStyle = styled(a.img)`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
  @media (min-width: 1280px) {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const OptsSection = styled.section`
  display: flex;
  justify-content: center;
  gap: 1rem;
  @media (min-width: 1280px) {
    gap: 0;
  }

  & > * {
    margin: 1.9rem 0;
    @media (min-width: 1280px) {
      margin: 4.5rem 1rem;
    }
  }
`;

export const OptButtonContainer = styled(a.div)`
  background-color: #ed672b;
  color: white;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.info};
  }

  @media (min-width: 768px) {
    height: 6.3rem;
    width: 6.3rem;
  }

  @media (min-width: 1280px) {
    height: 6.3rem;
    width: 6.3rem;
  }
`;

export const OptContent = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
`;

export const Label = styled(a.p)`
  text-align: center;
  margin-top: 0.8rem;
  font-size: 1rem;
  width: 4.3rem;
  line-height: 1;
  color: #1f3463;

  @media (min-width: 768px) {
    font-size: 2rem;
    width: 10rem;
  }

  @media (min-width: 1280px) {
    font-size: 2rem;
    width: 10rem;
  }
`;

export const BulletsContainer = styled.div`
  display: grid;
  padding: 0;
  grid-template: auto/1fr;
  gap: 1.2rem;
  margin-bottom: 0;
  @media (min-width: 1280px) {
    grid-template: auto/repeat(2, 1fr);
    padding: 0 4rem;
    margin-bottom: 2rem;
  }
`;

const CheckIconColor = color =>
  ({
    primary: ({ theme }) => theme.colors.primary,
    warning: ({ theme }) => theme.colors.warning,
  }[color ?? 'primary']);

export const CheckIconStyle = styled.img`
  background: ${({ color }) => CheckIconColor(color)};
  padding: 0.2rem;
  height: 1.5rem;
  border-radius: 50rem;
  width: 1.5rem;
`;

export const CheckItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

export const CheckText = styled.p`
  margin: 0 !important;
  line-height: normal;
  text-align: left !important;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  @media (min-width: 1280px) {
    margin-top: 0;
  }
`;

export const BottomMessage = styled(CheckText)`
  color: ${({ theme }) => theme.colors.warning};
  font-weight: bold;
  text-align: center !important;
  font-size: 12px !important;
  @media (min-width: 1280px) {
    font-size: 1.6rem !important;
  }
`;

const ButtonColor = variant =>
  ({
    primary: ({ theme }) => theme.colors.primary,
    secondary: ({ theme }) => theme.colors.dark,
  }[variant ?? 'primary']);

export const CustomButton = styled.button`
  padding: 1.2rem 4.4rem;
  border: none;
  background-color: ${({ variant }) => ButtonColor(variant)};
  color: white;
  border-radius: 20rem;
  margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }

  @media (min-width: 1280px) {
    margin: 0 0 0 75%;
    position: absolute;
    bottom: 7%;
  }
`;
